import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-input',
    templateUrl: 'input.component.html',
    styleUrls: ['input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class InputComponent implements ControlValueAccessor, OnChanges, OnInit {
    @ViewChild('input') input;
    @ViewChild(MatAutocomplete) auto: MatAutocomplete;
    @Output() clearFieldEvent = new EventEmitter<any>();
    @Output() valueChangedHandler = new EventEmitter<string>();
    @Input() formControlName: string;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() hasError: boolean = false;
    @Input() errorMessage = '';
    @Input() type: string = 'search';
    @Input() max: number;
    @Input() min: number;
    @Input() step: number = 0;
    @Input() removeSuffix: boolean = false;
    @Input() required: boolean = false;
    @Input() maxLength: number = 255;
    @Input() isDisabled: boolean = false;
    @Input() isFocused: boolean = false;
    @Input() autocompleteOptions: string[] | null = null;
    inputValue = '';
    @Input() visible: boolean = true;
    changeType: boolean = true;
    processedOptions: Observable<string[]>;
    @Input() initValue: any;

    ngOnInit() {
        this.processedOptions = of([]);

        if (this.initValue > 0 || this.initValue != '') {
            this.valueChangeFromString(this.initValue);
            this.valueChangedHandler.emit(this.initValue);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentOptions = changes['autocompleteOptions']?.currentValue;
        this.processedOptions = of(currentOptions?.length ? this.autocompleteOptions : []);
    }

    public writeValue(value: any) {
        this.inputValue = value;

        if (this.input?.nativeElement) {
            this.input.nativeElement.value = value;
        }
    }

    propagateChange: (_: any) => void = undefined;

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
        // TODO implement on touched function
    }

    valueChangeEvent(event: Event) {
        this.valueChangeFromString((<HTMLInputElement>event.target).value);
    }

    valueChangeFromString(value: string) {
        this.inputValue = value;
        if (this.propagateChange) {
            this.propagateChange(this.inputValue);
        }
        this.valueChangedHandler.emit(this.inputValue);
    }

    valueChangeFromAutoComplete(value: string) {
        if (this.type === 'worklog-popup') {
            if (this.inputValue.split('/')[1]) {
                this.inputValue = this.inputValue.split('/')[0] + '/' + value;
                this.valueChangeFromString(this.inputValue);
            } else if (this.inputValue.split('?')[1]) {
                this.inputValue = this.inputValue.split('?')[0] + '?' + value;
                this.valueChangeFromString(this.inputValue);
            } else {
                this.inputValue = '#' + value;
            }
        } else {
            this.valueChangeFromString(value);
        }
    }

    removeValue() {
        this.clearFieldEvent.emit();
    }

    viewPass() {
        this.visible = !this.visible;
        this.changeType = !this.changeType;
    }

    setFocused(value: boolean) {
        this.isFocused = value;
    }
}
