import { Pipe, PipeTransform } from '@angular/core';
import { TranslationLanguageCode } from '@app/enum/translation-language-code.enum';
import { DateFormats } from '@app/model/date-formats';
import { LanguageService } from '@app/service/language.service';
import * as moment from 'moment';
import { getDateFormatByTranslationLanguageCode } from 'src/helper/dateHelper';

@Pipe({
    name: 'formatDate',
    pure: true,
})
export class DateFormatPipe implements PipeTransform {
    constructor(private languageService: LanguageService) {}

    transform(date: Date | string, formats: DateFormats = null): string {
        let result = moment(date).format(getDateFormatByTranslationLanguageCode(this.languageService.getLanguage()));

        if (formats?.hu && this.languageService.getLanguage() === TranslationLanguageCode.HU) {
            result = moment(date).format(formats.hu);
        }
        if (formats?.en && this.languageService.getLanguage() === TranslationLanguageCode.EN) {
            result = moment(date).format(formats.en);
        }

        return result;
    }
}
