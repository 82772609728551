import { ChangeDetectionStrategy, Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-blue-button',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'blue-button.component.html',
    styleUrls: ['blue-button.component.scss'],
})
export class BlueButtonComponent {
    @Input() title: string;
    @Input() type: string = 'button';
    @Input() disabled: boolean = false;
    @Input() showAsDisabled: boolean = false;
    @Output() onButtonClick = new EventEmitter<any>();

    onClick = () => this.onButtonClick.emit();
}
