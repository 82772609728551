import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    COWORKER_TYPE,
    DEFAULT_PROFILE_PICTURE_PATH,
    DEFAULT_PROJECT_PICTURE_PATH,
    DEFAULT_SELECT_OPTION,
    PROJECT_TYPE,
    RIGHT_ARROW_PATH,
    COWORKER_MAX_ASSIGNED_NON_ZERO_FTE_PROJECT,
} from '@app/constants/constants';
import { FteValue } from '@app/enum/fte-value.enum';
import { Role } from '@app/enum/role.enum';
import { DropdownItem } from '@app/model/dropdown-item.model';
import { Project } from '@app/model/project.model';
import { ProjectService } from '@app/service/project.service';
import { CoworkerService } from 'src/app/service/coworker.service';

import { Coworker } from 'src/app/model/coworker.model';
import { FormBuilderService } from 'src/app/service/form-builder.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { UserService } from '@app/service/user.service';
import { CoworkerToProjectAdditon } from '@app/model/coworker-to-project-addition.model';
import { CoworkerProject } from '@app/model/coworker-project.model';

@Component({
    selector: 'app-add-coworker-to-project-dialog',
    templateUrl: './add-coworker-to-project-dialog.component.html',
    styleUrls: ['./add-coworker-to-project-dialog.component.scss'],
})
export class AddCoworkerToProjectDialogComponent implements OnInit {
    readonly defaultProfilePicPath = DEFAULT_PROFILE_PICTURE_PATH;
    readonly defaultProjectPicPath = DEFAULT_PROJECT_PICTURE_PATH;
    readonly rightArrowPath = RIGHT_ARROW_PATH;
    readonly projectType = PROJECT_TYPE;
    readonly coworkerType = COWORKER_TYPE;

    fteForm: UntypedFormGroup;
    fteValues: DropdownItem[];
    isReviewer: boolean = false;
    isFteFieldDisabled: boolean = false;
    movedCoworker: Coworker;
    coworkerInDatabase: Coworker;
    assignedFteSumOfCoworker: number;
    project: Project;
    coworkerImage: any = `url(${this.defaultProfilePicPath})`;
    projectImage: any = `url(${this.defaultProjectPicPath})`;
    maxAssignableFte: number;
    movedFromPool: boolean;
    isAddedWithDrop: boolean;
    coworkerOptions: DropdownItem[] = [];
    coworkers: CoworkerToProjectAdditon[] = [];
    removeFromProjectOptions: DropdownItem[] = [];

    constructor(
        public matDialogRef: MatDialogRef<AddCoworkerToProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackbarService: SnackbarService,
        private formBuilderService: FormBuilderService,
        private projectService: ProjectService,
        private coworkerService: CoworkerService,
        private userService: UserService,
    ) {
        this.movedCoworker = this.data.movedCoworker;
        this.coworkerInDatabase = this.data.coworkerInDatabase;
        this.assignedFteSumOfCoworker = this.data.assignedFteSumOfCoworker;
        this.maxAssignableFte = this.data.maxAssignableFte ?? this.coworkerInDatabase?.fte;
        this.project = this.data.project;
        this.movedFromPool = this.data.movedFromPool;
        this.fteValues = this.getAvailableFteOptions(this.coworkerInDatabase);
        this.isReviewer =
            this.movedCoworker?.fte === FteValue.REVIEWER_OR_QA && this.movedCoworker?.role === Role.ENGINEER;

        this.createFteForm();
        this.isAddedWithDrop = this.data.isAddedWithDrop ?? true;
        this.fetchImages();
        this.setFteFieldDisableValue();
    }

    ngOnInit() {
        this.coworkerService
            .getCoworkersAssignableToProject(
                this.project.type,
                this.project.coworkers.map((coworker) => coworker?.id),
            )
            .subscribe((response) => (this.coworkers = response));
    }

    /**
     * The following method will sets the value of the isFteFieldDisabled variable based on the
     * value of the isReviewer and if the coworker's role is management type of role
     */
    private setFteFieldDisableValue() {
        this.isFteFieldDisabled = this.shouldTheFTEFieldBeDisabled();
    }

    /**
     * The following method will check if the given co-worker that has been moved in the website as a management type user or a reviewer.
     * If he/she is, then make the input field of the FTE disabled.
     */
    private shouldTheFTEFieldBeDisabled(): boolean {
        return this.isReviewer || this.coworkerService.isManagementRole(this.movedCoworker);
    }

    save() {
        this.fteForm.markAllAsTouched();
        if (this.fteForm.valid && this.movedCoworker) {
            this.movedCoworker.fte = this.fteForm.get('fte').value;
            (this.movedCoworker as unknown as CoworkerProject).removeFromProjectId =
                this.fteForm.get('removeFrom').value;
            this.matDialogRef.close(this.movedCoworker);
        } else if (this.fteForm.getRawValue().fte > 0 && !this.isReviewer && this.isAddedWithDrop) {
            this.snackbarService.openSnackbarWithMessage('project.coworker-fte-exceeded');
        } else {
            this.snackbarService.openSnackbarWithMessage('project.required-values');
        }
    }

    isEngineer() {
        return this.movedCoworker?.role === Role.ENGINEER;
    }

    disableFteFieldByCheckboxValue(isReviewer: boolean) {
        this.isReviewer = isReviewer;
        this.setFteFieldDisableValue();
        this.isReviewerChecked.setValue(isReviewer);

        if (isReviewer) {
            this.fte.setValue(FteValue.REVIEWER_OR_QA);
            this.fte.disable();
        } else {
            this.fte.setValue(this.movedCoworker?.fte);
            this.fte.enable();
        }
    }

    fetchImages() {
        this.movedCoworker &&
            this.userService.getProfilePicURL(this.movedCoworker.id).subscribe((picUrl) => {
                this.coworkerImage = picUrl;
            });

        if (this.projectImage === `url(${this.defaultProjectPicPath})`) {
            this.projectService.getProjectPic(this.project.id).subscribe((pic) => {
                this.setPictureAsDataUrl(pic, (dataUrl) => {
                    this.projectImage = dataUrl;
                });
            });
        }
    }

    setPictureAsDataUrl(pic: Blob, target: (dataUrl: string) => void): void {
        if (pic.type !== 'text/xml' && pic.size !== 0) {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                target(`url(${e.target.result}`);
            };
            fileReader.readAsDataURL(pic);
        }
    }

    moveWithSelectedFteWouldAffectOtherProjects(): boolean {
        return !this.isReviewer
            ? this.movedFromPool
                ? this.coworkerInDatabase?.fte <=
                  this.assignedFteSumOfCoworker - this.movedCoworker?.fte + this.fte.value
                : this.coworkerInDatabase?.fte <
                  this.assignedFteSumOfCoworker - this.movedCoworker?.fte + this.fte.value
            : false;
    }

    setSelectedCoworker(id: string | number): void {
        if (id === DEFAULT_SELECT_OPTION) {
            return;
        }

        const selectedCoworker = this.coworkers.find((coworker) => coworker.id === id);
        this.movedCoworker = selectedCoworker as unknown as Coworker;
        this.fteValues = this.getAvailableFteOptions(this.movedCoworker);
        this.fetchImages();

        if (selectedCoworker.role !== Role.ENGINEER) {
            this.fte.setValue(FteValue.REVIEWER_OR_QA);
        } else {
            this.createFteForm();
        }

        let numberOfHalfFteProjects = 0;
        let projectsWithHalfFte = [];

        for (const project of selectedCoworker?.projectAssociations) {
            if (project.assignedFte === FteValue.HALF) {
                numberOfHalfFteProjects++;
                projectsWithHalfFte.push(project);
            }

            if (numberOfHalfFteProjects === COWORKER_MAX_ASSIGNED_NON_ZERO_FTE_PROJECT) {
                this.removeFromProjectOptions = projectsWithHalfFte.map((item) => {
                    return {
                        id: item.projectId,
                        name: item.projectName,
                    };
                });
            }
        }
    }

    getAvailableFteOptions(coworker: Coworker): DropdownItem[] {
        return coworker?.fte === 0.5
            ? [new DropdownItem(0.5, '0.5')]
            : [new DropdownItem(0.5, '0.5'), new DropdownItem(1, '1')];
    }

    isSelectedFteHalf(): boolean {
        return this.fte.value === FteValue.HALF;
    }

    isProjectRemovalNeeded(): boolean {
        const result = this.removeFromProjectOptions.length && this.isSelectedFteHalf() && !this.isAddedWithDrop;

        if (result) {
            this.fteForm.get('isProjectRemovalNeeded').setValue(true);
        }

        return result;
    }

    createFteForm(): void {
        this.fteForm = this.formBuilderService.createFteForm(
            this.movedCoworker?.fte || null,
            this.isReviewer,
            this.maxAssignableFte,
            this.isProjectRemovalNeeded(),
        );
    }

    get fte() {
        return this.fteForm.get('fte');
    }

    get isReviewerChecked() {
        return this.fteForm.get('isReviewer');
    }
}
