import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime',
    pure: true,
})
export class TimeFormatPipe implements PipeTransform {
    transform(workTime: number): string {
        let hours: number = Math.floor(workTime / 60);
        let minutes: number = workTime % 60;

        const minutesString = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();
        const hoursString = hours < 10 ? `0${hours.toString()}` : hours.toString();

        return `${hoursString}:${minutesString}`;
    }
}
