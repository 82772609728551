import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../material.module';
import { AddCoworkerToProjectDialogComponent } from './add-coworker-to-project-dialog/add-coworker-to-project-dialog.component';
import { NewDocumentDialogComponent } from './document-dialogs/new-document-dialog/new-document-dialog.component';
import { NewFolderDialogComponent } from './document-dialogs/new-folder-dialog/new-folder-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TimesheetDetailsDialogComponent } from './timesheet-details/timesheet-details-dialog.component';
import { TimesheetDialogDetailsComponent } from './timesheet-details/timesheet-dialog-details/timesheet-dialog-details.component';
import { TimesheetDialogTimesheetComponent } from './timesheet-details/timesheet-dialog-timesheet/timesheet-dialog-timesheet.component';
import { TimesheetDialogWorklogComponent } from './timesheet-details/timesheet-dialog-worklog/timesheet-dialog-worklog.component';
import { BlueButtonModule } from 'src/app/shared/button/blue-button/blue-button.module';
import { StrokedButtonModule } from 'src/app/shared/button/stroked-button/stroked-button.module';
import { StrokedBlueButtonModule } from '@app/shared/button/stroked-blue-button/stroked-blue-button.module';
import { CheckboxModule } from 'src/app/shared/checkbox/checkbox.module';
import { FilterModule } from 'src/app/shared/filter/filter.module';
import { InputModule } from 'src/app/shared/input/input.module';
import { MultiSelectModule } from 'src/app/shared/multi-select/multi-select.module';
import { SelectModule } from '@app/shared/select/select.module';
import { ConfirmationDialogWithMessageComponent } from './confirmation-dialog-with-message/confirmation-dialog-with-message/confirmation-dialog-with-message.component';
import { PipeModule } from '@app/pipe/pipe.module';

@NgModule({
    declarations: [
        TimesheetDetailsDialogComponent,
        TimesheetDialogDetailsComponent,
        TimesheetDialogWorklogComponent,
        TimesheetDialogTimesheetComponent,
        NewFolderDialogComponent,
        ConfirmationDialogComponent,
        NewDocumentDialogComponent,
        TimesheetDetailsDialogComponent,
        AddCoworkerToProjectDialogComponent,
        ConfirmationDialogWithMessageComponent,
    ],
    exports: [TimesheetDetailsDialogComponent],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        FilterModule,
        InputModule,
        BlueButtonModule,
        StrokedBlueButtonModule,
        StrokedButtonModule,
        StrokedBlueButtonModule,
        ReactiveFormsModule,
        CheckboxModule,
        MultiSelectModule,
        SelectModule,
        PipeModule,
    ],
})
export class DialogModule {}
