<div class="select-wrapper">
    <mat-form-field hideRequiredMarker [class.disabled]="disabled" [class.error]="hasError" [class.focused]="isFocused">
        <mat-label class="label">{{ label | translate }}</mat-label>
        <mat-select
            (focus)="setFocused(true)"
            (blur)="setFocused(false)"
            disableOptionCentering
            required="{{ required }}"
            [disabled]="disabled"
            [(value)]="selectedOption"
            (selectionChange)="onSelectChange($event)"
            [placeholder]="!disabled ? (!hiddenSelectOption ? '' : (placeholder | translate)) : ''"
            [ngClass]="isDark ? 'dark' : 'light'"
        >
            <mat-select-trigger *ngIf="isNotSelectable">
                <mat-icon [ngClass]="isDark ? 'dark' : 'light'" class="filter-icon" svgIcon="filter-on-icon" />
                <div class="filter-text">
                    {{ messageUponOptionSelection }}
                </div>
            </mat-select-trigger>
            <mat-option *ngFor="let option of options" [value]="option.id">
                <ng-container>
                    <div *ngIf="isIconDisplayed" class="select-checkbox-holder">
                        <app-checkbox
                            [disableClickOnly]="true"
                            class="checkbox"
                            [value]="option.id === selectedOption"
                            [ngClass]="isDark ? 'dark' : 'light'"
                        />
                    </div>
                    {{ isTranslated ? (option.name | translate) : option.name }}
                </ng-container>
            </mat-option>
        </mat-select>
        <mat-error class="error" *ngIf="hasError">{{ errorMessage }}</mat-error>
    </mat-form-field>
</div>
