import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { NewFolderDialogComponent } from '../../dialogs/document-dialogs/new-folder-dialog/new-folder-dialog.component';
import { Folder } from 'src/app/model/folder.model';
import { DocumentService } from 'src/app/service/document.service';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { ADMINISTRATIVE_ROLES } from '@app/constants/constants';

@Component({
    selector: 'folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.scss'],
})
export class FolderComponent {
    readonly ADMINISTRATIVE_ROLES = ADMINISTRATIVE_ROLES;

    constructor(
        private dialog: MatDialog,
        private documentService: DocumentService,
        private router: Router,
        public tokenService: TokenStorageService,
    ) {}

    @Input() folder: Folder;
    @Output() refreshFolders = new EventEmitter<any>();

    openFolder() {
        this.router.navigate(['documents', this.folder.id]);
    }

    deleteFolder() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: { message: 'documents.delete-folder', yes: 'documents.yes', no: 'documents.no' },
                panelClass: 'confirm-dialog',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.value) {
                    this.documentService.deleteFolder(this.folder.id).subscribe(() => this.refreshFolders.emit());
                }
            });
    }

    editFolder() {
        this.dialog
            .open(NewFolderDialogComponent, {
                data: this.folder,
                panelClass: 'new-folder-dialog',
            })
            .afterClosed()
            .subscribe(() => this.refreshFolders.emit());
    }
}
