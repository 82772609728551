import { Pipe, PipeTransform } from '@angular/core';
import { Worklog } from '../model/worklog.model';

@Pipe({
    name: 'sum',
})
export class SumPipe implements PipeTransform {
    transform(items: Worklog[], startPeriod: Date, endPeriod: Date, attr: string, projectId: number): any {
        const fiteredItems: Worklog[] = [];

        items.forEach((item) => {
            const date = new Date(item.date);
            if (date >= startPeriod && date <= endPeriod && item.projectId === projectId) {
                fiteredItems.push(item);
            }
        });

        return fiteredItems.reduce((a, b) => a + b[attr], 0);
    }
}
