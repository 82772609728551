import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoApplyStyleToHead } from '@app/decorator/header-style.decorator';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
@AutoApplyStyleToHead
export class ConfirmationDialogComponent {
    iconURL = `/assets/icons/${this?.data?.icon}`;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    ) {}

    close(value: boolean) {
        this.matDialogRef.close({ value });
    }
}
