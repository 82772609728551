<div class="dialog">
    <div *ngIf="data.icon" class="w-100 d-flex justify-content-center align-items-center">
         <img [src]="iconURL" height="90px" class="icon"/>
    </div>
    <div class="text">
        <p>{{ data.message | translate }}</p>
    </div>
    <div class="d-flex justify-content-around buttons">
            <app-stroked-blue-button
                [title]="data.no | translate"
                [mat-dialog-close]="true"
                (onButtonClick)="close(false)"
            >
            </app-stroked-blue-button>
    
            <app-blue-button [title]="data.yes | translate" (onButtonClick)="close(true)">
            </app-blue-button>
    </div>
</div>
