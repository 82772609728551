import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxModule } from 'src/app/shared/checkbox/checkbox.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        CheckboxModule,
    ],
    declarations: [SelectComponent],
    exports: [SelectComponent],
})
export class SelectModule {}
