<div
    [ngClass]="
        shifts.length < 2 && isDeclined()
            ? 'declined-request'
            : shifts.length > 1 && isDeclined()
              ? 'declined-more-requests'
              : 'request'
    "
    [class.resized]="allShiftsFilled()"
    *ngIf="selectedDays?.length > 0 || shifts?.length > 0 || editMode"
>
    <header>
        {{ 'shifts.shift-request' | translate }}
        <svg height="30" width="30"><circle cx="15" cy="15" r="7" [class]="getCircleSvgStyle()" /></svg>
    </header>
    <ng-container *ngIf="!isDeclined(); else declineRequest">
        <div
            class="shifts-container"
            [ngClass]="splittedShift ? 'splitted-shift-container' : 'shifts-container'"
            [class.enlarged-container]="moreThanOneShift"
        >
            <div *ngFor="let shift of shifts; let i = index">
                <div (click)="changeEditedShift(shift)" class="shift">
                    <div class="top-container">
                        <div [ngClass]="shift?.dates?.length > 4 ? 'date-holder-big' : 'date-holder'">
                            <div class="dateText" *ngFor="let date of shift.dates">
                                {{ formatDate(date.date) }}
                            </div>
                        </div>

                        <div class="interval-container">
                            <div
                                [ngClass]="isDeclined() ? 'declined-shift-intervals' : 'intervals'"
                                (click)="changeEditedShift(shift)"
                            >
                                <div class="interval-selector">
                                    <app-duration-input
                                        [overWriteTime]="editedStartTime?.[i]"
                                        [maxHours]="23"
                                        [maxMinutes]="59"
                                        (valueChanged)="setFirstInterval($event, true)"
                                        #first
                                        [hideRequiredAsterisk]="true"
                                    ></app-duration-input>
                                </div>

                                <div class="separator">-</div>

                                <div class="interval-selector">
                                    <app-duration-input
                                        [overWriteTime]="editedEndTime?.[i]"
                                        [maxHours]="23"
                                        [maxMinutes]="59"
                                        (valueChanged)="setFirstInterval($event, false)"
                                        #second
                                        [hideRequiredAsterisk]="true"
                                    ></app-duration-input>
                                </div>
                            </div>
                            <div (click)="changeEditedShift(shift)" *ngIf="shift.isSplit" class="intervals">
                                <div class="interval-selector">
                                    <app-duration-input
                                        [maxHours]="23"
                                        [maxMinutes]="59"
                                        (valueChanged)="setSecondInterval($event, true)"
                                        #first
                                        [hideRequiredAsterisk]="true"
                                    ></app-duration-input>
                                </div>

                                <div class="separator">-</div>

                                <div class="interval-selector">
                                    <app-duration-input
                                        [maxHours]="23"
                                        [maxMinutes]="59"
                                        (valueChanged)="setSecondInterval($event, false)"
                                        #second
                                        [hideRequiredAsterisk]="true"
                                    ></app-duration-input>
                                </div>
                            </div>
                        </div>

                        <div class="split-button-container">
                            <button
                                [disabled]="canBeSplit(i)"
                                matTooltip="{{ 'shifts.split-shift-button' | translate }}"
                                [matTooltipPosition]="'below'"
                                [matTooltipClass]="'below'"
                                *ngIf="!shift.isSplit"
                                class="split-shift-button"
                                (click)="splitShift(shift)"
                            >
                                <mat-icon
                                    svgIcon="add-circle-outline"
                                    class="plus"
                                    [ngClass]="isDark ? 'dark' : 'light'"
                                ></mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'shifts.unsplit-shift-button' | translate }}"
                                [matTooltipPosition]="'below'"
                                [matTooltipClass]="'below'"
                                *ngIf="shift.isSplit"
                                class="split-shift-button"
                                (click)="splitShift(shift)"
                            >
                                <mat-icon class="plus" [ngClass]="isDark ? 'dark' : 'light'"
                                    >remove_circle_outline</mat-icon
                                >
                            </button>
                        </div>
                    </div>
                </div>
                <hr class="line" />
            </div>
        </div>
    </ng-container>
    <ng-template #declineRequest>
        <div [ngClass]="shifts.length < 2 ? 'declined-shift-container' : 'declined-shifts-container'">
            <div *ngFor="let shift of shifts; let i = index">
                <div class="declined-shift">
                    <div class="declined-top-container">
                        <div [ngClass]="shift?.dates?.length > 4 ? 'date-holder-big' : 'date-holder'">
                            <div class="dateText" *ngFor="let date of shift.dates">
                                {{ formatDate(date.date) }}
                            </div>
                        </div>
                        <div class="declined-interval">
                            {{ shift.intervals[0].start }}
                        </div>
                        <div class="declined-separator">-</div>

                        <div class="declined-interval">
                            {{ shift.intervals[0].end }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p [ngClass]="shifts.length < 2 ? 'decision-message' : 'decision-shifts-message'">
            {{ shiftToEdit?.decisionMessage | truncate: MAX_DECISION_LENGTH }}
        </p>
    </ng-template>

    <ng-container *ngIf="!isDeclined(); else declineButton">
        <div class="button-container">
            <button
                *ngIf="!editMode"
                matTooltip="{{ 'shifts.new-shift-button' | translate }}"
                [matTooltipPosition]="'left'"
                [matTooltipClass]="'left'"
                class="new-shift-button"
                (click)="addNewShift()"
            >
                <mat-icon svgIcon="add-circle-outline" class="plus" [ngClass]="isDark ? 'dark' : 'light'"></mat-icon>
            </button>
            <app-stroked-blue-button
                *ngIf="!editMode"
                class="cancel-button"
                [title]="'globalconstant.cancel' | translate"
                (onButtonClick)="onCancel()"
            ></app-stroked-blue-button>
            <app-stroked-blue-button
                *ngIf="editMode"
                class="cancel-button"
                [title]="'globalconstant.cancel' | translate"
                (onButtonClick)="onEditCancel()"
            ></app-stroked-blue-button>
            <app-blue-button
                class="save-button"
                [disabled]="!canBeSaved()"
                (onButtonClick)="onSave()"
                [title]="'globalconstant.save' | translate"
            ></app-blue-button>
        </div>
    </ng-container>
    <ng-template #declineButton>
        <div class="button-container">
            <app-blue-button
                class="save-button"
                (onButtonClick)="acceptDecision()"
                [title]="'globalconstant.accept' | translate"
            ></app-blue-button>
        </div>
    </ng-template>
</div>
