import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { LanguageService } from '@app/service/language.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { getDateFormatByTranslationLanguageCode } from 'src/helper/dateHelper';
@Injectable({
    providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
    private languageSubscription: Subscription;

    constructor(
        private languageService: LanguageService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(PLATFORM_ID) public platform_id: object,
    ) {
        super(locale, new Platform(platform_id));
        this.setLanguage();

        this.languageSubscription = this.languageService.languageChanged$.subscribe((lang: string) => {
            this.setLanguage();
        });
    }

    getFirstDayOfWeek(): number {
        return 1;
    }

    setLanguage() {
        let lang = this.languageService.getLanguage();
        this.setLocale(lang);
    }

    parse(value: any, parseFormat?: any): Date | null {
        return moment(value, getDateFormatByTranslationLanguageCode(this.locale), true).isValid()
            ? new Date(value)
            : null;
    }

    format(date: Date): string {
        return moment(date).format(getDateFormatByTranslationLanguageCode(this.locale));
    }
}
