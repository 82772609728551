import { AppearanceOptions } from '@app/enum/appearance-options.enum';
import { CalendarColor, getDarkVersionColor } from '@app/enum/calendar-color.enum';

export function toggleMode(appearance: AppearanceOptions) {
    const currentAppearance =
        appearance == AppearanceOptions.LIGHT
            ? getCssClassName(AppearanceOptions.DARK)
            : getCssClassName(AppearanceOptions.LIGHT);
    const newApperance = getCssClassName(appearance);

    Array.from(document.getElementsByClassName('mat-ink-bar')).forEach((element) => {
        element.classList.add('mat-ink-bar-override');
    });

    Array.from(document.getElementsByClassName(currentAppearance)).forEach((element) => {
        element.classList.remove(currentAppearance);
        element.classList.add(newApperance);
    });

    setTimeout(() => {
        Array.from(document.getElementsByClassName('mat-ink-bar')).forEach((element) => {
            element.classList.remove('mat-ink-bar-override');
        });
    }, 10);
}

function getCssClassName(appearance: AppearanceOptions): string {
    return appearance.toString().toLowerCase();
}

export function getDarkColor(color: string): string {
    const enumValue = Object.keys(CalendarColor)[Object.values(CalendarColor).indexOf(color as CalendarColor)];

    return getDarkVersionColor(enumValue);
}
