import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Observable } from 'rxjs';
import { IdentityResponse } from '@model/identity-response.model';
import { SocialLoginRequest } from '@model/social-login-request.model';
import { refreshTokenResponse } from '@app/model/refresh-token-response.model';
import { toggleMode } from '@app/../helper/appearanceSwitchHelper';
import { AppearanceOptions } from '@app/enum/appearance-options.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private isUserLoggedInViaSocial: boolean;

    constructor(
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private tokenStorage: TokenStorageService,
        private router: Router,
        private socialAuth: SocialAuthService,
    ) {}

    socialLogin(socialUser: SocialUser) {
        this.sendIdTokenForAuthentication({
            token: socialUser.idToken,
            provider: socialUser.provider,
        }).subscribe((loginResponse: IdentityResponse) => {
            this.handleSuccessfulLoginResponse(loginResponse);
            this.isUserLoggedInViaSocial = true;
        });
    }

    private sendIdTokenForAuthentication(loginRequest: SocialLoginRequest): Observable<IdentityResponse> {
        return this.httpService.post<any>('/login/oauth2', loginRequest);
    }

    private handleSuccessfulLoginResponse(identityResponse: IdentityResponse) {
        this.tokenStorage.saveToken(identityResponse.token);
        this.tokenStorage.saveRefreshToken(identityResponse.refreshToken);
        this.tokenStorage.saveUser(identityResponse);
        this.router.navigate(['/dashboard']);
    }

    signOutUser() {
        if (this.isUserLoggedInViaSocial) {
            this.socialAuth.signOut();
            this.isUserLoggedInViaSocial = false;
        }
        this.tokenStorage.removeAuthToken().then(() => {
            this.router.navigate(['/login']).then(() => {
                this.tokenStorage.clearAuthTokens();
            });
        });
    }

    refreshToken(refreshToken: string): Observable<refreshTokenResponse> {
        return this.httpService.post<any>('/user/refresh-token', refreshToken);
    }
}
