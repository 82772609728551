import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CalendarDayDate } from '@app/model/calendar-day-date.model';
import { CalendarDay } from '@app/model/calendar-day.model';
import { ShiftRequest } from '@app/model/shift-request';
import { ShiftTime } from '@app/model/shift-time.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogWithMessageComponent } from '@app/view/dialogs/confirmation-dialog-with-message/confirmation-dialog-with-message/confirmation-dialog-with-message.component';
import { Shift } from '@app/model/shift.model';
import { ShiftState } from '@app/enum/shift-state.enum';
import { ShiftService } from '@app/service/shift.service';
import { TokenStorageService } from '@app/service/token-storage.service';
import { HolidayService } from '@service/holiday.service';
import { Subscription } from 'rxjs';
import { ShiftDate } from '@app/model/shift-date.model';
import { parseISO } from 'date-fns';
import { formatShiftTime } from 'src/helper/shiftHelper';
import { ShiftTimes } from '@app/model/shift-times.model';
import { ConfirmationDialogComponent } from '@app/view/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { workdayFilter } from 'src/helper/holidayHelper';
import { isSameDate } from 'src/helper/holidayHelper';
import { ShiftChangeRequest } from '@app/model/shift-change-request';
import { AutoApplyStyleToHead } from '@app/decorator/header-style.decorator';
import { AppearanceOptions } from '@app/enum/appearance-options.enum';
import { AutoUnsubscribe } from '@app/decorator/auto-unsubscribe.decorator';

@Component({
    selector: 'app-shifts-request',
    templateUrl: './shifts-request.component.html',
    styleUrls: ['./shifts-request.component.scss'],
})
@AutoApplyStyleToHead
@AutoUnsubscribe
export class ShiftRequestComponent implements OnInit {
    private savedShiftsSubscription: Subscription;
    private sharedServiceSubscription: Subscription;
    private selectedDaysSubscription: Subscription;
    private editedShiftSubscription: Subscription;
    private editModeSubscription: Subscription;
    private shiftRequestSubscription: Subscription;
    private dialogSubscription: Subscription;
    readonly MAX_DECISION_LENGTH: number = 40;
    @Input() emitRefresh: () => void;

    editMode: boolean;
    shiftToEdit: ShiftDate = null;
    selectedDays: CalendarDayDate[] = [];
    addNew: boolean;
    shiftRequest: ShiftRequest;
    shiftRequests: ShiftRequest[];
    shifts: Shift[] = [];
    savedShiftRequests: ShiftDate[] = [];
    weekDays: Date[] = [];
    inputText: string = '';
    currentShift: number = 0;
    allDates: CalendarDayDate[] = [];
    editedStartTime: string[] = [];
    editedEndTime: string[] = [];
    isSplit: boolean;
    moreThanOneShift: boolean = false;
    shifTimeFail: boolean = false;
    splittedShift: boolean = false;
    declinedShift?: Shift = null;
    acceptDecisionSubscription?: Subscription;
    isDark: boolean = false;
    darkModeSubscription: Subscription = this.tokenStorageService.appearance$.subscribe((option) => {
        this.isDark = option === AppearanceOptions.DARK;
    });

    @ViewChild('first') firstDiv: ElementRef;
    @ViewChild('second') secondDiv: ElementRef;

    private readonly SUNDAY = 0;
    private readonly SATURDAY = 6;
    private readonly MIN_NUMBERS = 10;
    private readonly EARLIEST_START_HOUR = 6;
    private readonly LATEST_END_HOUR = 20;
    private readonly MIN_HOURS_ON_A_DAY = 4;
    private readonly MAX_HOURS_ON_A_DAY = 12;
    private readonly MIN_HOURS_FOR_SPLIT_SHIFT = 2;

    constructor(
        private holidayService: HolidayService,
        private shiftService: ShiftService,
        private tokenStorageService: TokenStorageService,
        private snackbarService: SnackbarService,
        private dialog: MatDialog,
    ) {}
    ngOnInit(): void {
        this.savedShiftsSubscription = this.shiftService.savedShifts$.subscribe((shifts) => {
            this.savedShiftRequests = shifts;
        });

        this.selectedDaysSubscription = this.shiftService.selectedDays$.subscribe((days) => {
            this.selectedDays = [];
            this.weekDays = workdayFilter(
                days,
                (date) => this.holidayService.isWeekendWorkday(date),
                (date) => this.holidayService.isNationalHoliday(date),
            );
            days.forEach((day) => {
                this.weekDays.forEach((weekday) => {
                    if (day.date.valueOf() === weekday.valueOf()) {
                        this.selectedDays.push(day);
                    }
                });
            });
            this.selectedDays = this.filterOutApprovedAndPendingShifts(this.selectedDays);

            this.loadRequest();
        });
        this.editedShiftSubscription = this.shiftService.shiftToEdit$.subscribe((shift) => {
            this.shiftToEdit = shift;

            if (this.shiftToEdit) {
                this.loadEditedShift(this.shiftToEdit);
            } else if (!this.shiftToEdit) {
                this.shifts = [];
                this.editedStartTime = [];
                this.editedEndTime = [];
                this.shiftService.setSelectedDays([]);
                this.editMode = false;
                this.shiftService.setEditMode(this.editMode);
            }
        });
        this.editModeSubscription = this.shiftService.editMode$.subscribe((editing) => {
            this.editMode = editing;
        });
    }

    ngOnChange(): void {
        this.getShiftTimes();
    }

    getShiftTimes() {
        return this.shifts.every((shift: Shift) => {
            return shift.intervals.every((shiftTime: ShiftTimes) => {
                return shiftTime.start;
            });
        });
    }

    createEmptyShift() {
        const shift = new Shift();
        shift.dates = [];
        shift.intervals = [];
        const interval = new ShiftTimes('00:00', '00:00');
        shift.intervals.push(interval);

        return shift;
    }

    loadRequest() {
        if (!this.editMode) {
            if (this.shifts.length > 0 && this.selectedDays.length === 0 && !this.addNew) {
                this.shifts = [];
            } else if (this.shifts.length === 0 && this.selectedDays.length !== 0) {
                this.shifts = [];
                const shift = this.createEmptyShift();

                this.selectedDays.map((value) => {
                    shift.dates.push(value);
                });

                const compareDates = (a, b) => a.date - b.date;
                this.selectedDays.sort(compareDates);
                this.shifts.push(shift);
            } else if (this.shifts.length != 0) {
                const compareDates = (a, b) => a.date - b.date;
                this.selectedDays.sort(compareDates);
                this.shifts[this.currentShift].dates = [];
                if (
                    !this.selectedDaysContainsRequestByState(ShiftState.APPROVED) &&
                    !this.selectedDaysContainsRequestByState(ShiftState.PENDING) &&
                    !this.selectedDaysContainsRequestByState(ShiftState.REQUESTED_DELETE) &&
                    !this.selectedDatesAreNationalHolidayInInterval()
                ) {
                    this.selectedDays.map((value) => {
                        if (!this.isDateAlreadyAdded(value)) {
                            this.shifts[this.currentShift].dates.push(value);
                        } else if (
                            this.isDateAlreadyAdded(value) &&
                            !this.shifts[this.currentShift - 1].dates.includes(value)
                        ) {
                            this.snackbarService.openSnackbarWithMessage('shifts.selected-before');
                        }
                    });
                } else {
                    this.snackbarService.openSnackbarWithMessage('shifts.shift-already-approved-pending-holiday');
                    this.onCancel();
                }
            }
        } else {
            const indexesToDelete = [];

            if (this.shifts.length > this.selectedDays.length && this.selectedDays.length != 0) {
                for (let i = 0; i < this.shifts.length; i++) {
                    if (this.shifts[i].dates.some((date) => !this.isInSelectedDays(date))) {
                        indexesToDelete.push(i);
                    }
                }

                const numberOfIndexesToDelete = indexesToDelete.length;

                for (let i = 0; i < numberOfIndexesToDelete; i++) {
                    const indexToDelete = indexesToDelete.pop();
                    this.shifts.splice(indexToDelete, 1);
                    this.editedStartTime.splice(indexToDelete, 1);
                    this.editedEndTime.splice(indexToDelete, 1);
                }
            } else if (this.shifts.length < this.selectedDays.length) {
                const shift = this.createEmptyShift();
                shift.dates.push(this.selectedDays[this.selectedDays.length - 1]);

                this.shifts.push(shift);
            }
        }
    }

    isInSelectedDays(date): boolean {
        return this.selectedDays.some((selectedDate) => selectedDate.day == date.day);
    }

    isDateAlreadyAdded(dateToAdd: CalendarDayDate): boolean {
        this.allDates = [];

        for (let shift of this.shifts) {
            this.allDates = this.allDates.concat(shift.dates);
        }

        const index = this.allDates.findIndex((value) => value.date === dateToAdd.date);
        if (index >= 0) {
            const indexInSelected = this.selectedDays.findIndex((value) => value.date === dateToAdd.date);
            this.selectedDays.splice(indexInSelected);
            this.shiftService.setSelectedDays(this.selectedDays);
        }

        return index >= 0;
    }

    changeEditedShift(value) {
        this.currentShift = this.shifts.findIndex((shift) => shift === value);
        this.selectedDays = this.shifts[this.currentShift].dates;
    }

    allShiftsFilled(): boolean {
        let filledShifts = 0;

        this.shifts.forEach((shift: Shift) => {
            if (shift.dates?.length > 0) {
                filledShifts++;
            }
        });

        return filledShifts === this.shifts.length;
    }

    addNewShift() {
        if (this.lastShiftNotTheSameTimes()) {
            this.snackbarService.openSnackbarWithMessage('shifts.start-and-end-same');
        } else {
            this.snackbarService.openSnackbarWithMessage('shifts.new-shift-request-date-select');
            this.addNew = true;
            const shift = new Shift();
            shift.intervals = [];
            const interval = new ShiftTimes('00:00', '00:00');
            shift.intervals.push(interval);
            this.shifts.push(shift);
            this.shiftService.setShifts(this.shifts);
            this.currentShift = this.shifts.length - 1;
            this.moreThanOneShift = this.shifts.length > 1;
        }
    }

    splitShift(value): void {
        this.splittedShift = !this.splittedShift;
        this.currentShift = this.shifts.findIndex((shift) => {
            if (shift === value) {
                shift.isSplit
                    ? ((shift.intervals = [shift.intervals[0]]), (shift.isSplit = false))
                    : (shift.intervals.push(new ShiftTimes('00:00', '00:00')), (shift.isSplit = true));
            }
        });
    }

    lastShiftNotTheSameTimes(): boolean {
        const lastShift: Shift = this.shifts[this.shifts.length - 1];

        return lastShift.intervals[0].start === lastShift.intervals[0].end;
    }

    canBeSaved() {
        return this.shifts.length;
    }

    /**
     * Checks if the description is at least 10 characters long without whitespace
     * @returns {boolean}  Returns true if the input is valid, otherwise false.
     */
    isValidText(text: string): boolean {
        const inputString = text.replace(/\s+/g, '');
        return inputString.length > this.MIN_NUMBERS;
    }

    /**
     * Create a new date with the given shift time
     * @returns {Date}  Returns with the new date time.
     */
    formatDateTimes(date: Date, dateTime: string): Date {
        const [hours, minutes] = dateTime.split(':');
        date.setHours(Number(hours), Number(minutes), 0, 0);

        return date;
    }

    isStartTimeAfter(): boolean {
        return this.shifts.some((shift: Shift) => {
            if (shift.intervals[1]) {
                const startTimeFirstInterval =
                    parseFloat(shift.intervals[0].start.split(':')[0]) +
                    parseFloat(shift.intervals[0].start.split(':')[1]) / 100;
                const endTimeFirstInterval =
                    parseFloat(shift.intervals[0].end.split(':')[0]) +
                    parseFloat(shift.intervals[0].end.split(':')[1]) / 100;
                const startTimeSecondInterval =
                    parseFloat(shift.intervals[1].start.split(':')[0]) +
                    parseFloat(shift.intervals[1].start.split(':')[1]) / 100;
                const endTimeSecondInterval =
                    parseFloat(shift.intervals[1].end.split(':')[0]) +
                    parseFloat(shift.intervals[1].end.split(':')[1]) / 100;
                return !(
                    startTimeFirstInterval < endTimeFirstInterval &&
                    endTimeFirstInterval < startTimeSecondInterval &&
                    startTimeSecondInterval < endTimeSecondInterval
                );
            } else {
                const startTime =
                    parseFloat(shift.intervals[0].start.split(':')[0]) +
                    parseFloat(shift.intervals[0].start.split(':')[1]) / 100;
                const endTime =
                    parseFloat(shift.intervals[0].end.split(':')[0]) +
                    parseFloat(shift.intervals[0].end.split(':')[1]) / 100;
                return startTime >= endTime;
            }
        });
    }

    checkWrongLength(): string | null {
        for (const shift of this.shifts) {
            let sumOfHours = 0;
            let isSplitIntervalValid = true;

            for (const shiftTime of shift.intervals) {
                const startTimeFirstParts = shiftTime.start.split(':');
                const startTimeFirstInterval = parseInt(startTimeFirstParts[0]) * 60 + parseInt(startTimeFirstParts[1]);
                const endTimeFirstParts = shiftTime.end.split(':');
                const endTimeFirstInterval = parseInt(endTimeFirstParts[0]) * 60 + parseInt(endTimeFirstParts[1]);
                const interval = endTimeFirstInterval - startTimeFirstInterval;
                sumOfHours += interval;

                if (!this.isIntervalValid(interval)) {
                    if (shift.intervals.length === 1) {
                        return 'shifts.wrong-length';
                    } else if (interval < this.MIN_HOURS_FOR_SPLIT_SHIFT * 60) {
                        isSplitIntervalValid = false;
                    }
                }
            }

            if (!this.isIntervalValid(sumOfHours)) {
                return 'shifts.wrong-length';
            } else if (!isSplitIntervalValid) {
                return 'shifts.both-shifts-must-be-longer';
            }
        }

        return null;
    }

    containsRequestByState(state: ShiftState): boolean {
        return this.shifts.some((shift) =>
            shift.dates.some((date) => {
                const shiftDate = date.date;
                return this.savedShiftRequests.some(
                    (savedShift) => isSameDate(new Date(savedShift.date), shiftDate) && savedShift.state === state,
                );
            }),
        );
    }

    selectedDaysContainsRequestByState(state: ShiftState): boolean {
        return this.selectedDays.some((selectedDay) => {
            return this.savedShiftRequests.some(
                (savedShift) => isSameDate(new Date(savedShift.date), selectedDay.date) && savedShift.state === state,
            );
        });
    }

    isStartOrEndNull(): boolean {
        return this.shifts.some((shift: Shift) => {
            if (shift.intervals[1]) {
                return (
                    shift.intervals[0].start === '00:00' ||
                    shift.intervals[0].end === '00:00' ||
                    shift.intervals[1].start === '00:00' ||
                    shift.intervals[1].end === '00:00'
                );
            } else {
                return shift.intervals[0].start === '00:00' || shift.intervals[0].end === '00:00';
            }
        });
    }

    doesShiftNotHaveDates(): boolean {
        return this.shifts.some((shift: Shift) => !shift.dates);
    }

    isRequestedDelete(): boolean {
        return this.shifts.some((shift: Shift) => shift.state === ShiftState.REQUESTED_DELETE);
    }

    isDeclined(): boolean {
        return this.containsRequestByState(ShiftState.DECLINED);
    }

    onSave() {
        if (!this.isRequestValid()) {
            return;
        }

        if (!this.shiftToEdit) {
            this.shiftRequests = [];
            for (let shift of this.shifts) {
                this.shiftRequest = new ShiftRequest();
                this.shiftRequest.userId = this.tokenStorageService.getUserId();
                this.shiftRequest.dates = [];
                this.shiftRequest.startDate = this.formatDateTimes(
                    new Date(shift.dates[0].date),
                    shift.intervals[0].start,
                );
                this.shiftRequest.endDate = this.formatDateTimes(new Date(shift.dates[0].date), shift.intervals[0].end);

                for (let date of shift.dates) {
                    const shiftTime = new ShiftTime();
                    shiftTime.date = new Date(date.date);
                    shiftTime.start = this.formatDateTimes(new Date(date.date), shift.intervals[0].start);
                    shiftTime.end = this.formatDateTimes(new Date(date.date), shift.intervals[0].end);
                    this.shiftRequest.dates.push(shiftTime);

                    if (shiftTime.start < this.shiftRequest.startDate) {
                        this.shiftRequest.startDate = shiftTime.start;
                    }

                    if (shiftTime.end > this.shiftRequest.endDate) {
                        this.shiftRequest.endDate = shiftTime.end;
                    }

                    if (shift.intervals[1]) {
                        const shiftTime = new ShiftTime();
                        shiftTime.date = new Date(date.date);
                        shiftTime.start = this.formatDateTimes(new Date(date.date), shift.intervals[1].start);
                        shiftTime.end = this.formatDateTimes(new Date(date.date), shift.intervals[1].end);
                        this.shiftRequest.dates.push(shiftTime);
                    }
                }

                this.shiftRequests.push(this.shiftRequest);
            }

            this.setDescriptionAndSendRequest(this.sendShiftRequest.bind(this));
        } else {
            const updatedShift = new ShiftChangeRequest();
            updatedShift.id = this.shiftToEdit.id;
            updatedShift.userId = this.shiftToEdit.userId;
            updatedShift.state = ShiftState.CHANGE_REQUESTED;
            updatedShift.shiftTimes = [];
            updatedShift.date = this.shifts[0].dates[0].date;
            updatedShift.startDate = this.shifts[0].dates[0].date;
            updatedShift.endDate = this.shifts[0].dates[0].date;
            updatedShift.decisionMessage = null;

            for (let shift of this.shifts) {
                for (let interval of shift.intervals) {
                    const shiftTime = new ShiftTime();
                    shiftTime.date = new Date(shift.dates[0].date);
                    shiftTime.start = this.formatDateTimes(new Date(shift.dates[0].date), interval.start);
                    shiftTime.end = this.formatDateTimes(new Date(shift.dates[0].date), interval.end);

                    if (shiftTime.start < updatedShift.startDate) {
                        updatedShift.startDate = shiftTime.start;
                    }

                    if (shiftTime)
                        if (shiftTime.end > updatedShift.endDate) {
                            updatedShift.endDate = shiftTime.end;
                        }

                    updatedShift.shiftTimes.push(shiftTime);
                }
            }

            if (this.isUpdateShiftRequestMatchesWithSentRequest(updatedShift)) {
                this.snackbarService.openSnackbarWithMessage('error.SHIFT_UPDATE_REQUEST_MATCHES_WITH_ORIGINAL_SHIFT');

                return;
            }

            this.setDescriptionAndSendRequest(this.sendShiftUpdate.bind(this), updatedShift);
        }
    }

    resetAndRefresh() {
        this.reset();
        this.emitRefresh();
    }

    isRequestValid() {
        if (this.isStartOrEndNull()) {
            this.snackbarService.openSnackbarWithMessage('shifts.start-or-end-null');
            return false;
        } else if (this.isStartTimeAfter()) {
            this.snackbarService.openSnackbarWithMessage('shifts.start-time-wrong');
            return false;
        } else if (this.doesShiftNotHaveDates()) {
            this.snackbarService.openSnackbarWithMessage('shifts.no-date');
            return false;
        } else if (!this.isInsidePermittedInterval()) {
            this.snackbarService.openSnackbarWithMessage('shifts.outside-of-permitted-interval');
            return false;
        } else if (this.containsRequestByState(ShiftState.APPROVED) && !this.editMode) {
            this.snackbarService.openSnackbarWithMessage('shifts.shift-already-approved');
            return false;
        } else if (this.containsRequestByState(ShiftState.PENDING)) {
            this.snackbarService.openSnackbarWithMessage('shifts.shift-already-pending');
            return false;
        } else if (this.containsRequestByState(ShiftState.REQUESTED_DELETE)) {
            this.snackbarService.openSnackbarWithMessage('shifts.cant-save-on-deleted-requested');
            return false;
        } else if (this.isNationalHolidayInInterval()) {
            this.snackbarService.openSnackbarWithMessage('shifts.shift-contains-national-holiday');
            return false;
        } else {
            const lengthValidationResult = this.checkWrongLength();

            if (lengthValidationResult !== null) {
                this.snackbarService.openSnackbarWithMessage(lengthValidationResult);
                return false;
            }
        }

        return true;
    }

    onCancel() {
        this.shifts = [];
        this.currentShift = 0;
        this.shiftService.setShifts(this.shifts);
        this.shiftService.setSelectedDays([]);
    }

    onEditCancel() {
        this.dialogSubscription = this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    message: 'shifts.shift-edit-cancel',
                    yes: 'globalconstant.yes',
                    no: 'globalconstant.cancel',
                },
                panelClass: 'confirm-dialog',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.value) {
                    this.selectedDays = [];
                    this.shifts = [];
                    this.currentShift = 0;
                    this.editedStartTime = [];
                    this.editedEndTime = [];
                    this.shiftService.setSelectedDays([]);
                    this.shiftService.setShiftToEdit(null);
                    this.shiftService.setShifts(this.shifts);
                    this.editMode = false;
                    this.shiftService.setEditMode(this.editMode);
                }
            });
    }

    async setFirstInterval(shiftTime, isFirstInput) {
        setTimeout(() => {
            const currentShift = this.shifts[this.currentShift];
            isFirstInput ? (currentShift.intervals[0].start = shiftTime) : (currentShift.intervals[0].end = shiftTime);
        }, 10);
    }

    async setshiftDateFail(result) {
        this.shifTimeFail = result;
    }

    async setSecondInterval(shiftTime, isFirstInput) {
        setTimeout(() => {
            const currentShift = this.shifts[this.currentShift];
            isFirstInput ? (currentShift.intervals[1].start = shiftTime) : (currentShift.intervals[1].end = shiftTime);
        }, 10);
    }

    formatDate(date: Date): string {
        return this.holidayService.formatDate(date, '-');
    }

    filterWeekendsAndNationalHolidays(dateObjects: CalendarDay[]): Date[] {
        const dates: Date[] = dateObjects.map((obj: CalendarDay) => {
            return new Date(obj.date);
        });

        const weekdays = dates.filter((date) => date.getDay() !== this.SUNDAY && date.getDay() !== this.SATURDAY);

        const weekdaysWithoutHolidays = weekdays.filter((day) => {
            return !this.holidayService.isNationalHoliday(day);
        });

        return weekdaysWithoutHolidays;
    }

    onInputTextChanged(event: Event): void {
        this.inputText = (event.target as HTMLInputElement).value;
    }

    loadEditedShift(shiftToEdit: ShiftDate) {
        if (!shiftToEdit) {
            return;
        }

        this.shifts = [];
        this.selectedDays = [];
        this.editMode = true;
        this.shiftService.setEditMode(this.editMode);

        shiftToEdit.shiftTimes.map((dateTime) => {
            const startTime = formatShiftTime(dateTime.start);
            const endTime = formatShiftTime(dateTime.end);
            const date = parseISO(dateTime.start + 'Z');
            const calendarDayDate: CalendarDayDate = { date: date, day: date.getDate() };

            const shift = new Shift();
            shift.dates = [];
            shift.intervals = [new ShiftTimes(null, null)];
            shift.intervals[0].start = startTime;
            shift.intervals[0].end = endTime;
            shift.dates = [calendarDayDate];
            this.shifts.push(shift);
            const index = this.shifts.findIndex((s) => s === shift);
            this.editedStartTime[index] = startTime;
            this.editedEndTime[index] = endTime;
        });
        this.inputText = shiftToEdit.description;
        this.selectedDays = [...this.shifts.map((shift) => shift.dates[0])];
        this.changeEditedShift(this.shifts[0]);
    }

    setReasoningMessage(message: string) {
        this.inputText = message;
    }

    canBeSplit(shiftIndex: number) {
        const shift = this.shifts[shiftIndex];

        return shift && (shift.intervals[0].start === '00:00' || shift.intervals[0].end === '00:00');
    }

    reset() {
        this.shifts = [];
        this.shiftRequests = [];
        this.currentShift = 0;
        this.selectedDays = [];
        this.inputText = '';
        this.editMode = false;
        this.shiftService.setShifts(this.shifts);
        this.shiftService.setSelectedDays(this.selectedDays);
        this.shiftService.setShiftToEdit(null);
        this.shiftService.fetchSavedShifts();
        this.shiftService.setEditMode(this.editMode);
        this.snackbarService.openSnackbarWithMessage('shifts.success');
    }

    isCurrentShift(value) {
        const shift = this.shifts.findIndex((shift) => shift === value);
        return shift === this.currentShift;
    }

    private isInsidePermittedInterval(): boolean {
        return this.shifts.every((shift: Shift) => {
            return shift.intervals.every((shiftTime: ShiftTimes) => {
                const startStringParts = shiftTime.start.split(':');
                const startTimeMinutesFromToday = parseInt(startStringParts[0]) * 60 + parseInt(startStringParts[1]);
                const earlistStartInMinutesFromToday = this.EARLIEST_START_HOUR * 60;

                const endStringParts = shiftTime.end.split(':');
                const endTimeMinutesFromToday = parseInt(endStringParts[0]) * 60 + parseInt(endStringParts[1]);
                const latestEndInMinutesFromToday = this.LATEST_END_HOUR * 60;

                return (
                    startTimeMinutesFromToday >= earlistStartInMinutesFromToday &&
                    endTimeMinutesFromToday <= latestEndInMinutesFromToday
                );
            });
        });
    }

    private isIntervalValid(interval: number): boolean {
        return interval >= this.MIN_HOURS_ON_A_DAY * 60 && interval <= this.MAX_HOURS_ON_A_DAY * 60;
    }

    private isNationalHolidayInInterval(): boolean {
        return this.shifts.some((shift: Shift) => {
            return shift.dates.some((calendarDate: CalendarDayDate) =>
                this.holidayService.isNationalHoliday(calendarDate.date),
            );
        });
    }
    private selectedDatesAreNationalHolidayInInterval(): boolean {
        return this.selectedDays.some((selectedDay) => {
            return this.holidayService.isNationalHoliday(selectedDay.date);
        });
    }

    private filterOutApprovedAndPendingShifts(days: CalendarDayDate[]): CalendarDayDate[] {
        if (days.length === 0) {
            return this.selectedDays;
        }

        this.savedShiftRequests
            .filter((shift) => shift.state !== ShiftState.APPROVED_DELETE)
            .forEach((shift) => {
                days = days.filter((day) => {
                    let shiftDateStart = new Date(shift.startDate).setHours(0, 0, 0, 0);
                    let shiftDateEnd = new Date(shift.endDate).setHours(0, 0, 0, 0);
                    let dayDate = new Date(day.date).setHours(0, 0, 0, 0);
                    return dayDate < shiftDateStart || dayDate > shiftDateEnd;
                });
            });
        return days;
    }

    getCircleSvgStyle(): string {
        if (this.shiftToEdit?.state == ShiftState.DECLINED) {
            return 'circle-declined';
        }

        return 'hidden-circle';
    }
    acceptDecision() {
        this.acceptDecisionSubscription = this.shiftService.acceptDecision(this.shiftToEdit.id).subscribe({
            next: () => {
                this.shifts = [];
                this.reset();
            },
            error: () => {
                this.snackbarService.openSnackbarWithMessage('shifts.accept-decision-failed');
            },
        });

        this.shiftToEdit = null;
    }

    private isUpdateShiftRequestMatchesWithSentRequest(updatedShift: ShiftChangeRequest): boolean {
        if (updatedShift.shiftTimes.length !== this.shiftToEdit.shiftTimes.length) {
            return false;
        }

        return updatedShift.shiftTimes.every((shiftTime: ShiftTime) =>
            this.shiftToEdit.shiftTimes.some(
                (editShiftTime: ShiftTimes) =>
                    editShiftTime.start === shiftTime.start.toISOString().split('.')[0] &&
                    editShiftTime.end === shiftTime.end.toISOString().split('.')[0],
            ),
        );
    }

    private setDescriptionAndSendRequest(
        callback: (updateRequest?: ShiftChangeRequest) => void,
        updateRequest?: ShiftChangeRequest,
    ): void {
        this.dialogSubscription = this.dialog
            .open(ConfirmationDialogWithMessageComponent, {
                data: {
                    page: 'shift',
                    isTextOptional: true,
                    placeholdertext: 'shifts.shift-send-reasoning',
                    message: 'shifts.send-confirmation',
                    yes: 'globalconstant.send',
                    no: 'globalconstant.cancel',
                },
                panelClass: 'confirm-dialog',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result?.value) {
                    if (updateRequest) {
                        updateRequest.description = result.reasoning;
                        callback(updateRequest);
                    } else {
                        this.shiftRequests = this.shiftRequests.map((shiftRequest: ShiftRequest) => {
                            shiftRequest.description = result.reasoning;

                            return shiftRequest;
                        });

                        callback();
                    }
                }
            });
    }

    private sendShiftUpdate(updatedShift: ShiftChangeRequest): void {
        this.shiftRequestSubscription = this.shiftService
            .updateShiftByUser(updatedShift)
            .subscribe(() => this.resetAndRefresh());
    }

    private sendShiftRequest(): void {
        this.shiftRequestSubscription = this.shiftService.sendShiftRequest(this.shiftRequests).subscribe(() => {
            this.reset();
            this.shiftService.refresh();
        });
    }
}
