import { Pipe, PipeTransform } from '@angular/core';
import { TokenStorageService } from 'src/app/service/token-storage.service';

@Pipe({
    name: 'formatNumber',
    pure: true,
})
export class NumberFormatPipe implements PipeTransform {
    constructor(private tokenService: TokenStorageService) {}

    private LOCAL = 'hu';

    transform(value: number): string {
        return new Intl.NumberFormat(this.LOCAL, {
            maximumFractionDigits: 0,
        }).format(value);
    }
}
