<div class="position-relative w-100" >
    <mat-form-field [class.disabled]="isDisabled" [class.error]="hasError" [class.focused]="isFocused" >
        <mat-label class="ps-2">{{ label | translate }}</mat-label>
        <input
            (focus)="setFocused(true)"
            (blur)="setFocused(false)"
            #input
            matInput
            required="{{ required }}"
            autocomplete="off"
            [placeholder]="placeholder"
            [value]="inputValue"
            [max]="max"
            [min]="min"
            [step]="step"
            [disabled]="isDisabled"
            [maxLength]="maxLength"
            (input)="valueChangeEvent($event)"
            [matAutocomplete]="auto"
            [matAutocompleteDisabled]="!processedOptions"
            [type]="type"
        />

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChangeFromAutoComplete($event.option.value)">
            <div *ngFor="let option of processedOptions | async">
                <mat-option [value]="option">{{ option }}</mat-option>
            </div>
        </mat-autocomplete>

        <button
            class="remove-btn"
            *ngIf="inputValue && removeSuffix"
            matSuffix
            mat-icon-button
            tabIndex="-1"
            (click)="removeValue()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-hint class="mat-error position-absolute" *ngIf="hasError">{{ errorMessage }}</mat-hint>
</div>
