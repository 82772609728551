import { AutoApplyStyleToHead } from '@app/decorator/header-style.decorator';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { AutoUnsubscribe } from '@app/decorator/auto-unsubscribe.decorator';
import { InputComponent } from '@app/shared/input/input.component';
import { SearchbarComponent } from '@app/shared/searchbar/searchbar.component';
import { SelectComponent } from '@app/shared/select/select.component';
import { StyleModel } from '@app/model/style.model';

/**
 * A service that handles the addition of style nodes to the head upon
 * components initialization, and removal of those style nodes upon the destruction
 * of the components.
 */
@Injectable({
    providedIn: 'root',
})
@AutoUnsubscribe
export class StyleService {
    public static loading = false;
    private static styleMap: Map<string, StyleModel> = new Map();
    private static http = new HttpClient(
        new HttpXhrBackend({
            build: () => new XMLHttpRequest(),
        }),
    );
    private subscription?: Subscription;
    private readonly COMPONENT = 'Component';

    /**
     * The head style of the listed components won't be
     * removed from the head, even after the components are destroyed,
     * for the purpose of preloading the head styles of the components,
     * so the components could be displayed more smoothly.
     *
     * Additionally, the components present in this list must not use the {@link AutoApplyStyleToHead}
     * decorator, because the angular might throw an unexpected error because of that.
     *
     * The components present in this list will be preloaded after the first initialization
     * of the service and appended to the {@link styleMap}.
     */
    private readonly PERSISTENT_HEAD_STYLE_COMPONENT_LIST = [
        InputComponent.name,
        SearchbarComponent.name,
        SelectComponent.name,
    ];

    constructor() {
        if (StyleService.styleMap.size === 0) {
            this.PERSISTENT_HEAD_STYLE_COMPONENT_LIST.forEach((component) => this.addStyle(component));
        }
    }

    /**
     * Read the file in the provided path.
     *
     * @param pathToFile Path to the file to read.
     * @returns Returns the read file in text format.
     */
    private readFile(pathToFile: string): Observable<string> {
        return StyleService.http.get(pathToFile, { responseType: 'text' });
    }

    /**
     * Splits the provided key by the uppercase letters, removes the 'Component' element from the list
     * and join the remaining elements by the '-' character.
     * - If the value of the key is `CoworkerCardComponent`,
     * then the result list we get after the use of the split operator will be: `['Coworker', 'Card', 'Component']`
     *
     * After this, we will filter the list by removing the `Component` element from the list.
     * - If the value of the modified key is `CoworkerCardComponent`, then the result will be this: `['Coworker', 'Card']`
     *
     * After this, we map the remaining elements of the list to lowercase and join them together by the '-' character.
     * - If the value of the modified key is `CoworkerCardComponent`, then the result will be this: `coworker-card`.
     **/
    private splitByUppercaseCharactersAndJoinThemByDash(key: string): string {
        return key
            .split(/(?=[A-Z])/)
            .filter((result) => result !== this.COMPONENT)
            .map((result) => result.toLowerCase())
            .join('-');
    }

    /**
     * Get the path to the style that needs to be added to the head based on
     * the value of the key that must be the name of an angular component.
     *
     * @param key The name of the angular component.
     * @returns Returns the path to the style file.
     */
    private getPathToStyle(key: string) {
        const modifiedKey = `${key}Head`;
        const pathToTheFilePathWithoutExtension = [
            'assets',
            'styles',
            this.splitByUppercaseCharactersAndJoinThemByDash(modifiedKey),
        ].join('/');
        const pathToTheFilePathWithExtension = [
            pathToTheFilePathWithoutExtension,
            this.COMPONENT.toLowerCase(),
            'scss',
        ].join('.');

        return pathToTheFilePathWithExtension;
    }

    /**
     * Creates a style node that will be appended to the head of the application.
     * This contains the styles what we want to apply to the components globally.
     *
     * @param content styles in text format.
     * @returns Returns a style node.
     */
    private createStyleNode(content: string): HTMLStyleElement {
        const styleElement = document.createElement('style');
        styleElement.textContent = content;

        return styleElement;
    }

    /**
     * Checks whether the style is already applied on the head.
     *
     * @param styleElement The {@link HTMLStyleElement} object to check.
     * @returns true if the styled has been already applied on the head.
     */
    private isStyleAlreadyAppliedToHead(styleElement: HTMLStyleElement): boolean {
        for (let i = 0; i < document.head.childNodes.length; i++) {
            if (document.head.childNodes.item(i).textContent === styleElement.textContent) {
                return true;
            }
        }

        return false;
    }

    /**
     * Append a new style to the head based on the provided key that hold the name of the component,
     * where the {@link AutoApplyStyleToHead} decorator is called. If the style have been already appended to the head,
     * then the append process of the style will be terminated.
     *
     * Based on the name of the component, it will search for the a file to load from the assets/styles directory.
     * This is the directory of those style files, which we want to append to the head if the component is initialized.
     *
     * @param key The name of the component where the {@link AutoApplyStyleToHead} decorator was called.
     */
    addStyle(key: string): void {
        const styleNode = StyleService.styleMap.get(key);

        if (styleNode) {
            styleNode.count++;
            return;
        } else {
            /**
             * Add a placeholder element to the map.
             * Why do we need this? If the same component, but in multiple instances tried to called this service,
             * then only one of those instances will be go beyond this line of code, instead of running the core of the
             * method for every instances.
             */
            StyleService.styleMap.set(key, {
                style: null,
                count: 0,
            });
        }

        /**
         * Be aware that this function will return before this line every time because we
         * initialize the current component's styleMap above to null.
         * Otherwise it would cause loading to be always true.
         */
        StyleService.loading = true;
        this.subscription = this.readFile(this.getPathToStyle(key)).subscribe((styleInText) => {
            if (!styleInText) {
                return;
            }

            const styleElement = this.createStyleNode(styleInText);

            if (this.isStyleAlreadyAppliedToHead(styleElement)) {
                return;
            }

            StyleService.styleMap.set(key, {
                style: styleElement,
                count: 1,
            });
            document.head.appendChild(styleElement);

            StyleService.loading = false;
        });
    }

    /**
     * Checks whether the provided key is removable from the map. If the key
     * is included in the persistent list, then it is not removable.
     *
     * @param key The key to check.
     * @returns Returns true if the key is removable, else false.
     */
    private isKeyNotRemovableFromMap(key: string): boolean {
        return this.PERSISTENT_HEAD_STYLE_COMPONENT_LIST.includes(key);
    }

    /**
     * Removes a head style from the document head if the component is destroyed.
     *
     * @param key The name of the component where the {@link AutoApplyStyleToHead} decorator was called.
     */
    removeStyle(key: string) {
        const styleNode = StyleService.styleMap.get(key);

        if (!styleNode || this.isKeyNotRemovableFromMap(key)) {
            return;
        }

        styleNode.count--;
        if (styleNode.count === 0) {
            document.head.removeChild(styleNode.style);
            StyleService.styleMap.delete(key);
        }
    }
}
