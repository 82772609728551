export enum CalendarColor {
    SUN_YELLOW = '#FCC010',
    ORANGE = '#F4971B',
    BRIGHT_RED = '#F9473A',
    WINE_RED = '#CD2E55',
    PINK = '#F6BCD0',
    GREY = '#DEDCDD',
    GREYISH_GREEN = '#9DBFAE',
    GREEN = '#8DC04E',
    FOREST_GREEN = '#13955F',
    DARK_GREEN = '#335F4B',
    STEEL_GREY = '#627E88',
    DARK_BLUE = '#4153A1',
    INTERN_BLUE = '#438ECC',
    TURQUOISE = '#1EB8D1',
    LAKE_GREEN = '#088EA7',
    CALM_PURPLE = '#7268A6',
}

enum DarkCalendarColor {
    SUN_YELLOW = '#FFBE00',
    ORANGE = '#F4971B',
    BRIGHT_RED = '#F9473A',
    WINE_RED = '#CD2E55',
    PINK = '#F6BCD0',
    GREY = '#DEDCDD',
    GREYISH_GREEN = '#9DBFAE',
    GREEN = '#8DC04E',
    FOREST_GREEN = '#13955F',
    DARK_GREEN = '#335F4B',
    STEEL_GREY = '#627E88',
    DARK_BLUE = '#4153A1',
    INTERN_BLUE = '#438ECC',
    TURQUOISE = '#1EB8D1',
    LAKE_GREEN = '#088EA7',
    CALM_PURPLE = '#7268A6',
}

export const getDarkVersionColor = (color: string): string => {
    switch (CalendarColor[color]) {
    case CalendarColor.SUN_YELLOW:
        return DarkCalendarColor.SUN_YELLOW;
    case CalendarColor.ORANGE:
        return DarkCalendarColor.ORANGE;
    case CalendarColor.BRIGHT_RED:
        return DarkCalendarColor.BRIGHT_RED;
    case CalendarColor.WINE_RED:
        return DarkCalendarColor.WINE_RED;
    case CalendarColor.PINK:
        return DarkCalendarColor.PINK;
    case CalendarColor.GREY:
        return DarkCalendarColor.GREY;
    case CalendarColor.GREYISH_GREEN:
        return DarkCalendarColor.GREYISH_GREEN;
    case CalendarColor.GREEN:
        return DarkCalendarColor.GREEN;
    case CalendarColor.FOREST_GREEN:
        return DarkCalendarColor.FOREST_GREEN;
    case CalendarColor.DARK_GREEN:
        return DarkCalendarColor.DARK_GREEN;
    case CalendarColor.STEEL_GREY:
        return DarkCalendarColor.STEEL_GREY;
    case CalendarColor.DARK_BLUE:
        return DarkCalendarColor.DARK_BLUE;
    case CalendarColor.INTERN_BLUE:
        return DarkCalendarColor.INTERN_BLUE;
    case CalendarColor.TURQUOISE:
        return DarkCalendarColor.TURQUOISE;
    case CalendarColor.LAKE_GREEN:
        return DarkCalendarColor.LAKE_GREEN;
    case CalendarColor.CALM_PURPLE:
        return DarkCalendarColor.CALM_PURPLE;
    }
};

export const getEnumFromDarkVersionColor = (color: string): CalendarColor => {
    switch (color) {
    case DarkCalendarColor.SUN_YELLOW:
        return CalendarColor.SUN_YELLOW;
    case DarkCalendarColor.ORANGE:
        return CalendarColor.ORANGE;
    case DarkCalendarColor.BRIGHT_RED:
        return CalendarColor.BRIGHT_RED;
    case DarkCalendarColor.WINE_RED:
        return CalendarColor.WINE_RED;
    case DarkCalendarColor.PINK:
        return CalendarColor.PINK;
    case DarkCalendarColor.GREY:
        return CalendarColor.GREY;
    case DarkCalendarColor.GREYISH_GREEN:
        return CalendarColor.GREYISH_GREEN;
    case DarkCalendarColor.GREEN:
        return CalendarColor.GREEN;
    case DarkCalendarColor.FOREST_GREEN:
        return CalendarColor.FOREST_GREEN;
    case DarkCalendarColor.DARK_GREEN:
        return CalendarColor.DARK_GREEN;
    case DarkCalendarColor.STEEL_GREY:
        return CalendarColor.STEEL_GREY;
    case DarkCalendarColor.DARK_BLUE:
        return CalendarColor.DARK_BLUE;
    case DarkCalendarColor.INTERN_BLUE:
        return CalendarColor.INTERN_BLUE;
    case DarkCalendarColor.TURQUOISE:
        return CalendarColor.TURQUOISE;
    case DarkCalendarColor.LAKE_GREEN:
        return CalendarColor.LAKE_GREEN;
    case DarkCalendarColor.CALM_PURPLE:
        return CalendarColor.CALM_PURPLE;
    }
};
