import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'whereCoworkerName',
    pure: false,
})
export class SearchWorklog implements PipeTransform {
    transform(items: any[], args: any = ''): any {
        if (items && args.length) {
            return items.filter((item) => item.coworkerName.toUpperCase().indexOf(args.toUpperCase()) > -1);
        }
        return items;
    }
}
