import { NgModule } from '@angular/core';
import { SumPipe } from './sum.pipe';
import { SearchWorklog } from './search-worklog.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { TruncatePipe } from './truncate.pipe';
import { JsonTranslatePipe } from './json-translate.pipe';
import { DateFormatPipe } from './date-format.pipe';

@NgModule({
    declarations: [
        SumPipe,
        SearchWorklog,
        NumberFormatPipe,
        TimeFormatPipe,
        TruncatePipe,
        JsonTranslatePipe,
        DateFormatPipe,
    ],
    exports: [
        SumPipe,
        SearchWorklog,
        NumberFormatPipe,
        TimeFormatPipe,
        TruncatePipe,
        JsonTranslatePipe,
        DateFormatPipe,
    ],
})
export class PipeModule {}
