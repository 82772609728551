import { DAY_IN_MILLIS, SATURDAY, SUNDAY } from '@app/constants/constants';
import { HolidayState } from '@app/enum/holiday-state.enum';
import { CalendarDay } from '@app/model/calendar-day.model';
import { ExtraHoliday } from '@app/model/extra-holiday.model';
import { HolidayPeriod } from '@app/model/holiday-period.model';
import { HolidayResponse } from '@app/model/holiday-response.model';
import Holiday from '@app/model/holiday.model';
import * as moment from 'moment';
import { getDateFormatByTranslationLanguageCode } from 'src/helper/dateHelper';

/**
 * Formats period to string
 */
export const formatPeriod = (period: HolidayPeriod) => {
    let result = period.startDay;
    if (period.startDay != period.endDay && period.endDay) {
        result += ' - ' + period.endDay;
    }

    return result;
};

/**
 * Formats holiday to readable text by separating periods.
 */
export const formatHoliday = (holiday: HolidayResponse | Holiday, locale: string): string => {
    let result = '';

    holiday.periods.forEach((period) => {
        result += moment(new Date(period.startDay)).format(getDateFormatByTranslationLanguageCode(locale));

        if (period.startDay != period.endDay) {
            result += ' - ' + moment(new Date(period.endDay)).format(getDateFormatByTranslationLanguageCode(locale));
        }

        if (period != holiday.periods[holiday.periods.length - 1]) {
            result += ', ';
        }
    });

    return result;
};

/**
 * Checks if two Date objects represent the same date.
 *
 * @param {Date} date1 - The first Date object.
 * @param {Date} date2 - The second Date object.
 * @returns {boolean} Returns true if both Date objects have the same year, month, and day; otherwise, returns false.
 *
 */
export const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};

/**
 * Calculates length of the holiday
 * @param nationalHolidays: Dates which should not be counted as they are national holidays
 */
export const numberOfDaysInHoliday = (
    holiday: Holiday | HolidayResponse,
    isWeekendWorkday: (date: Date) => boolean,
    isNationalHoliday: (date: Date) => boolean,
): number => {
    let days = 0;
    holiday.periods.map((p) => {
        days += numberOfDaysInPeriod(
            p,
            (date) => isWeekendWorkday(date),
            (date) => isNationalHoliday(date),
        );
    });
    return days;
};

/**
 * Calculates length of the period
 * @param nationalHolidays: Dates which should not be counted as they are national holidays
 */
export const numberOfDaysInPeriod = (
    period: HolidayPeriod,
    isWeekendWorkday: (date: Date) => boolean,
    isNationalHoliday: (date: Date) => boolean,
): number => {
    const startDate = new Date(period.startDay);
    const endDate = new Date(period.endDay);
    var differenceTime = endDate.getTime() - startDate.getTime();

    const daysWithWeekends = differenceTime / DAY_IN_MILLIS + 1;
    if (daysWithWeekends === 1 && period.halfDay) {
        return 0.5;
    }

    const parts = period.startDay.split('-');
    let firstDay = parts[2];

    let daysInPeriod = [];
    for (let i = 0; i < daysWithWeekends; i++) {
        const dateObject = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(firstDay) + i);
        const dayObject = Number(firstDay) + i;
        daysInPeriod.push({ date: dateObject, day: dayObject });
    }

    return workdayFilter(
        daysInPeriod,
        (date) => isWeekendWorkday(date),
        (date) => isNationalHoliday(date),
    ).length;
};

/**
 * Creates a Date matrix from CalendarDay array, where each array in the matrix consists of
 * days following each other in the calendar without gaps. It is used for creating
 * periods from randomly selected days in the Holiday Calendar.
 *
 * Example: [ Jan21, Jan19, Jan22] -> [ [ Jan19 ], [ Jan21, Jan22 ] ]
 * @param selectedDays - Array of selected days
 * @param workDays - Workdays of the selected days (selectedDays withoud weekends or national holidays)
 * @returns
 */
export const separateToPeriods = (selectedDays: CalendarDay[], workDays: Date[]): Date[][] => {
    const requestedPeriods = [];

    if (!areSelectedDaysContinous(selectedDays)) {
        const orderedDays = sortDays([...workDays]);

        requestedPeriods[0] = [];
        requestedPeriods[0][0] = orderedDays.shift();
        let currentPeriod = 0;

        while (orderedDays.length) {
            const day = orderedDays.shift();

            const lastDayOfLastPeriod = requestedPeriods[currentPeriod][requestedPeriods[currentPeriod].length - 1];

            if (day.getDate() - 1 !== lastDayOfLastPeriod.getDate()) {
                currentPeriod++;
                requestedPeriods[currentPeriod] = [];
            }
            requestedPeriods[currentPeriod].push(day);
        }
    } else {
        requestedPeriods[0] = [...workDays];
    }

    return requestedPeriods;
};

/**
 * Sorts input date array ascending by date.
 * @param selectedDaysArray
 * @returns Sorted array.
 */
const sortDays = (selectedDaysArray: Date[]): Date[] => {
    return selectedDaysArray.sort((a, b) => {
        if (a.getTime() < b.getTime()) {
            return -1;
        } else if (a.getTime() > b.getTime()) {
            return 1;
        } else return 0;
    });
};

/**
 * Returns if selected days are after each other without a gap
 * @param selectedDays
 * @returns boolean
 */
const areSelectedDaysContinous = (selectedDays: CalendarDay[]): boolean => {
    if (selectedDays.length > 1) {
        let prevDay = selectedDays[0];
        for (let i = 1; i < selectedDays.length; i++) {
            if ((selectedDays[i]?.date as Date).getMonth() === (prevDay?.date as Date).getMonth()) {
                if (selectedDays[i]?.day - 1 !== prevDay?.day) {
                    return false;
                }
            } else {
                const lengthOfMonth = new Date(
                    (prevDay?.date as Date).getFullYear(),
                    (prevDay?.date as Date).getMonth() + 1,
                    0,
                ).getDate();
                if (selectedDays[i].day !== 1 || prevDay.day !== lengthOfMonth) {
                    return false;
                }
            }
            prevDay = selectedDays[i];
        }
    }
    return true;
};

/**
 * Returns true if one of the extra holiday inputs was not filled out.
 * @param extraHolidays - An array of extra holidays and their data.
 * @returns boolean
 */
export const extraHolidayNotFilledOut = (extraHolidays: ExtraHoliday[]): boolean => {
    return extraHolidays.some(
        (extraHoliday: ExtraHoliday) =>
            !extraHoliday.holidayType || extraHoliday.quantity === null || isNaN(extraHoliday.quantity),
    );
};

/**
 * Filters an array of CalendarDays and removes weekends (but not weekend workdays) and national holidays
 *
 * @param dateObjects A list of CalendarDays that the holiday calendar uses store selected or displayed dates
 * @param isWeekendWorkday A holiday service object's isWeekendWorkday function
 * @param isNationalHoliday A holiday service object's isNationalHoliday function
 * @returns The filtered list of CalendarDays
 */
export const workdayFilter = (
    dateObjects: CalendarDay[],
    isWeekendWorkday: (date: Date) => boolean,
    isNationalHoliday: (date: Date) => boolean,
): Date[] => {
    const dates: Date[] = dateObjects.map((obj: CalendarDay) => {
        return new Date(obj.date);
    });

    const weekdays = dates.filter(
        (date) => (date.getDay() !== SUNDAY && date.getDay() !== SATURDAY) || isWeekendWorkday(date),
    );

    const weekdaysWithoutHolidays = weekdays.filter((day) => {
        return !isNationalHoliday(day);
    });

    return weekdaysWithoutHolidays;
};

export const isHolidayRequestedForDelete = (holidayResponse: HolidayResponse): boolean => {
    return (
        holidayResponse.state === HolidayState.REQUESTED_DELETE ||
        holidayResponse.state === HolidayState.REQUESTED_DELETE_FROM_APPROVED
    );
};

export const getStatusOnAdminStatusChange = (currentState: HolidayState, eventState: HolidayState): HolidayState => {
    let result;
    const isDeclineEvent = eventState === HolidayState.DECLINED;
    const isApprovedEvent = eventState === HolidayState.APPROVED;
    const isCurrentRequestedDeleteFromApproved = currentState === HolidayState.REQUESTED_DELETE_FROM_APPROVED;
    const isCurrentRequestedDelete = currentState === HolidayState.REQUESTED_DELETE;

    if (isDeclineEvent) {
        if (isCurrentRequestedDeleteFromApproved) {
            result = HolidayState.APPROVED;
        } else if (isCurrentRequestedDelete) {
            result = HolidayState.DELETION_REQUEST_DENIED;
        } else {
            result = HolidayState.DECLINED;
        }
    } else if (isApprovedEvent) {
        if (isCurrentRequestedDeleteFromApproved || isCurrentRequestedDelete) {
            result = HolidayState.DELETION_APPROVED;
        } else {
            result = HolidayState.APPROVED;
        }
    }

    return result;
};
