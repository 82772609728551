import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'jsonTranslate',
})
export class JsonTranslatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(translationKey: string, jsonString?: string) {
        let parsedData = undefined;

        try {
            if (jsonString) {
                parsedData = JSON.parse(jsonString);
            }
        } catch (error) {
            console.error("Couldn't parse JSON string.");
            console.error(error);
        }

        return this.translateService.instant(translationKey, parsedData);
    }
}
