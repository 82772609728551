import { StyleService } from '@app/service/style.service';

export function AutoApplyStyleToHead(component: any) {
    // Init the service so we can add styles to the head of the app and remove them upon component destroy.
    const styleService = new StyleService();

    // Get a reference to the original ngOnInit function of the component
    const originalOnInit = component.prototype.ngOnInit;

    // Get a reference to the original ngOnDestroy function of the component
    const originalOnDestroy = component.prototype.ngOnDestroy;

    // Override the ngOnInit function of the component
    component.prototype.ngOnInit = function () {
        styleService.addStyle(component.name);
        // Apply the previously defined service method call to the ngOnInit hook of the component.
        originalOnInit?.apply(this, arguments);
    };

    // Override the ngOnDestroy function of the component
    component.prototype.ngOnDestroy = function () {
        styleService.removeStyle(component.name);
        // Apply the previously defined service method call to the ngOnDestroy hook of the component.
        originalOnDestroy?.apply(this, arguments);
    };
}
